import React from 'react';
import { Container, ContainerItem } from 'components/UIkit';
import { Privacy } from '.';

const Main = props => {


  return (
    <Container>
      <ContainerItem>
        <Privacy />
      </ContainerItem>
    </Container>
  )
}

export default Main;