import React from "react"
import { PaperBox } from "components/UIkit";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";


const Terms = () => {
  return (
  <>

    <Typography variant="h4" component={"h1"} textAlign={"center"}>
      プライバシーポリシー
    </Typography>

    <PaperBox sx={{p: 3, mt:2}} >
      <Typography variant="h6" sx={{m:2}}>Cookieについて</Typography>

      <Typography variant="body1" sx={{m:2}}>
        本ウェブサイトでは、利便性の向上を目的としてクッキーを使用しています。なお、お客様の個人情報を取得する目的では使用していません。
        <br />
        このCookieの機能はCookieを無効にすることで拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
      </Typography>

    </PaperBox>

    <PaperBox sx={{p: 3, mt:2}} >
      <Typography variant="h6" sx={{m:2}}>アクセス解析ツールについて</Typography>
      
      <ul>
        <li className="mt-2">当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。</li>
        <li className="mt-2">この規約に関して、詳しくは以下のURLからご確認ください。</li>
      </ul>

        <a href="https://policies.google.com/terms" target="_blank" rel="norefferrer" className="ms-4" >Google アナリティクス利用規約</a>
    </PaperBox>

    <PaperBox sx={{p: 3, mt:2}} className="d-none" >

      <Typography variant="h6" sx={{m:2}}>広告の配信について</Typography>
      <ul>
        <li className="mt-2">当サイトでは、第三者配信の広告サービス「Google Adsense グーグルアドセンス」を利用しています。このような広告配信事業者は、ユーザーの興味に応じた商品やサービスの広告を表示するため、当サイトや他サイトへのアクセスに関する情報 『Cookie』(氏名、住所、メール アドレス、電話番号は含まれません) を使用することがあります。このCookieの機能はCookieを無効にすることで拒否することが出来ますので、お使いのブラウザの設定をご確認ください。</li>
        <li className="mt-2">この規約に関して、詳しくは以下のURLからご確認ください。</li>
      </ul>
          <a href="https://policies.google.com/technologies/ads?hl=ja" target="_blank" rel="norefferrer" className="ms-4">広告 – ポリシーと規約 – Google</a>

    </PaperBox>

    <PaperBox sx={{p: 3, mt:2}} >

    <Typography variant="h6" sx={{m:2}}>IPアドレスの記録について</Typography>
    <ul>
      <li className="mt-2">一部サービスにおいて情報管理の目的でIPアドレスを収集する場合があります。サイト上ではIPアドレスが公開されることはありません。</li>
    </ul>

    </PaperBox>

    <PaperBox sx={{p: 3, mt:2}} >

    <Typography variant="h6" sx={{m:2}}>プライバシーポリシーの変更</Typography>
    <ul>
      <li className="mt-2">本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。</li>
      <li className="mt-2">当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。</li>
    </ul>

    </PaperBox>

    <br />
    <Grid container spacing={1.5}>
      <Grid item xs={6}>
        <PaperBox sx={{p: 3, display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <a href="https://forms.gle/hVQCJs2DnuCvqP788" target="_blank" rel="norefferrer">お問い合わせ口</a>
        </PaperBox>
      </Grid>
      <Grid item xs={6}>
        <PaperBox sx={{p: 3, display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
          <a href="https://forms.gle/LuWTboKg8Dn57wdn7" target="_blank" rel="norefferrer">改善要望・ご意見窓口</a>
        </PaperBox>
      </Grid>
    </Grid>

  </>
  )
}

export default Terms;