import * as React from 'react';
import Layout from "layout"
import { Main } from 'components/pages/Privacy';
import Seo from 'seo';

export default function Index() {
  return (
    <Layout>
      <Seo title={"プライバシーポリシー"} />
      <Main />
    </Layout>
  );
}
